<template>
    <v-checkbox
        :ripple="false"
        hide-details="auto"
        class="mt-0"
        v-model="value"
        :disabled="disabled"
        
    >
        <template slot="label" v-if="label != ''">
            <div class="mt-1 ml-1">
                {{label}}
            </div>
        </template>
    </v-checkbox>
</template>

<script>
export default {
    props: ['valueInput', 'rule', 'label', 'disabled'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    }
}
</script>