import TokenManager from "../helpers/TokenManager";
import store from "../store";
import VueGatesPlugin from '../plugins/vue-gates';

export default function AuthMiddleware({to,next, from}) {

    //console.log( to);
    // const tokenManager = new TokenManager();
    let token = TokenManager.getToken();
    console.log("from",from);
    if(token !== null && store.state.loggedIn && from.path != "/login"){
        if(to.path == '/login' || to.path == '/dashboard'){
            next('/');
        }
        store.state.layout = 'dashboard'
        document.title = to.meta.title
        next();
    }else{
        if(token !== null){
            store.$api.auth.me()
            .then((response) => {
                
                if(!response.data){
                    store.state.loggedIn = false
                }
                else{
                   // console.log(response, 'ME');
                    store.state.loggedIn = true
                    store.state.loggedUsername = response.data.employee.name;
                    store.state.userData = response.data;
                    VueGatesPlugin.init();
                }
                
                if(store.state.loggedIn){
                    if(to.meta.auth){
                        store.state.layout = 'dashboard'
                        document.title = to.meta.title
                        next();
                    }else{
                        store.state.layout = 'dashboard'
                        next('/');
                    }
                }else{
                    if(to.meta.auth){
                        store.state.layout = 'login'
                        next('login');
                    }else{
                        store.state.layout = 'login'
                        next()
                    }
                }
            })
            .catch((error) => {
              console.log(error, to)
           
                  TokenManager.removeToken();
                  store.state.layout = 'login'
                  next('login')
                  return;
           
            })
        }else{
            if(to.meta.auth){
                store.state.layout = 'login'
                next('login')
            }else{
                store.state.layout = 'login'
                next()
            }
        }
    }
}