const permissions = {
    admin:false,
    answers:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    areas:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    attendances:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    branch_offices:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    candidates:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    catalogs:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    employees:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    eventualities:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    positions:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    roles:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    surveys:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    users:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    vacancies:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    business_assets:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    departments:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    events:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    vacations:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    holidays:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    announcements:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
    calendar:{
        create:false,
        show:false,
        update:false,
        delete:false
    },
};

export default permissions;
