<template>
    
    <v-app-bar app color="background" elevation="0"  class="app-header " >
        <div class="d-flex flex-column align-left">
            <!-- <v-btn v-if="$store.state.menu" dark icon text @click="$store.state.minimenu = !$store.state.minimenu">
                <v-icon>mdi-menu</v-icon>
            </v-btn> -->
            <transition name="fade" mode="out-in">
             <h4 class="">{{ $route.meta.title }}</h4>
           
            </transition>
            
            <span class="caption">{{hours}}:{{minutes}}:{{seconds}} | {{getDate}}</span>
        </div>

        <v-spacer></v-spacer>

        <v-menu offset-y>
            
            <template v-slot:activator="{ on }">
                <v-btn  v-on="on" color="white" elevation="0" class="user-button">
                    <v-avatar color="primary" rounded size="30">
                        <span>{{user[0]}}</span>
                    </v-avatar>
                    <span class="mx-2 d-none d-sm-flex">{{user}}</span>
                    
                    <!-- <v-icon>mdi-chevron-down</v-icon> -->
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(item, index) in user_menu" :key="index">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout" link>
                    <v-list-item-title>Cerrar sesión</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div class="line-app-header">
                    
            <template>
                <v-progress-linear indeterminate :hidden="!this.$store.state.loading"></v-progress-linear>
            </template>
        </div>
    
    
    </v-app-bar>
  
   
</template>

<script>
import TokenManager from '../helpers/TokenManager';
    export default {
        name: 'Navbar',
        data(){
            return {
                
                hours: 0,
                minutes: 0,
                seconds: 0,
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

                dialog: false,
                items: [],
                user_menu: [],
                menu: [
                    {
                        title: 'Feature',
                        link: '/'
                    },
                ]
            }
        },
        computed: {
            getDate: function(){
                const actualDate = new Date();
                return `${actualDate.getDate()} ${this.months[actualDate.getMonth()]} ${actualDate.getFullYear()}`;
            },
            user: function() {
                return this.$store.state.loggedUsername??"test"
            }
        },
        methods: {

            
            getTime: function(){
                setInterval(() => {
                    const date = new Date()
                    this.hours = date.getHours()
                    this.minutes = this.checkSingleDigit(date.getMinutes())
                    this.seconds = this.checkSingleDigit(date.getSeconds())
                }, 1000)
            },
            checkSingleDigit (digit) {
                return ('0' + digit).slice(-2)
            },

            logout(){
                // const tokenManager = new TokenManager();
                TokenManager.removeToken();
                this.$router.push("/login");
            
            },
        },
        mounted () {
            this.getTime();
        }
    }
</script>
<style lang="scss">
    .app-header{
        margin: 7px;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            margin: 0px;
        }
    }

    .app-toolbar{
        padding: 0 !important;
    }
   .parent-class >>> .v-toolbar__content {
      padding: 0px !important;
    }

    .user-button{
        text-transform: unset !important;
    }

    .line-app-header{
        width: calc(100% - 32px);
        height: 1.5px;
        background: $lineColor-1;
        position: absolute;
        bottom: 0px;
        left: 16px;
        z-index: -10;
    }

    .v-toolbar__content{
        margin: 0 7px !important;
    }
</style>