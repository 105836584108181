<template>
	<div class="app-container">
        <div class="bar-top py-1">
            <v-img src="./../assets/logo-merca.svg" contain max-height="100%" :class="$vuetify.theme.dark ? 'icon-white' : 'icon-white' "></v-img>
        </div>
        <v-container fluid>
			
			
			<v-main class="mx-0 mx-lg-10">
			
				<!-- <div class="main-container mx-0 mx-md-10"> -->
					<div class="main-container">
						<transition name="fade" mode="out-in">
							<router-view></router-view>
						</transition>
					</div>
				<!-- </div> -->
				
				<app-footer></app-footer>
				
			</v-main>
			
        </v-container>
	</div>
</template>

<script lang="ts">
    // import VueGatesPlugin from '../plugins/vue-gates';
	import AppFooter from "../components/AppFooter.vue";
	export default {
		name: 'Dashboard',
		components: {
			AppFooter
		},
        computed: {
            routeName: {
                get: function() {
                    if(this.$route.name != 'Login')
                        return true;
                    return false;
                }
               
            },
            
        
        },
        mounted(){
            //VueGatesPlugin.init();
            //console.log(this.$route)
        }
	}
</script>

<style lang="scss">
	.main-container{
		margin: 0 12px;
		// display: flex;
		// flex-direction: column;
		// //height: 89vh;
		// min-height: 89vh;
		// justify-content: space-between;
		min-height: calc(100vh - 120px);
	}

	.app-container{
		background-color: var(--v-background-base);
	}


    .bar-top{
        height: 45px;
        background-color: #2c2e2e;
    }
	
</style>