<template>
    <v-textarea
        :label="requiredCharLabel"
        filled
        color="success"
        v-model="value" 
        hide-details="auto" 
        outlined 
        dense 
        :rules="rule"
        :error-messages="errorMessages"
    ></v-textarea>

</template>
<script>
export default {
    props: ['valueInput', 'rule', 'label', 'errorMessages'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value+"".trim())
            }
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]("") != true){
                        return response;
                    }
                }
                return this.label;
            }
        }
    }
}
</script>