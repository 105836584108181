<template>
  <v-app>
    <component :is="this.$store.state.layout"></component>
    <snackbar :props="$store.state.snackbarProps"></snackbar>

    <!-- <loading-overlay></loading-overlay> -->
  </v-app>
</template>


<script>
import "@/scss/_stylesheet.scss";
import snackbar from './components/Snackbar.vue';


export default {
  components: { snackbar },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
