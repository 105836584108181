<template>
	<div class="app-container">
        <v-container fluid>
			
			<sidemenu></sidemenu>
		
			<navbar class="mx-0 mx-lg-1"></navbar>
			
			<v-main class="mx-0 mx-lg-1">
			
				<!-- <div class="main-container mx-0 mx-md-10"> -->
					<div class="main-container">
						<transition name="fade" mode="out-in">
							<router-view></router-view>
						</transition>
					</div>
				<!-- </div> -->
				
				<app-footer></app-footer>
				
			</v-main>
			
        </v-container>
	</div>
</template>

<script lang="ts">
    // import VueGatesPlugin from '../plugins/vue-gates';
	import Sidemenu from "../components/Sidemenu.vue"; 
	import AppFooter from "../components/AppFooter.vue";
	import Navbar from "../components/Navbar.vue";
	export default {
		name: 'Dashboard',
		components: {
			Navbar,
			Sidemenu,
			AppFooter
		},
        computed: {
            routeName: {
                get: function() {
                    if(this.$route.name != 'Login')
                        return true;
                    return false;
                }
               
            },
            
        
        },
        mounted(){
            //VueGatesPlugin.init();
            //console.log(this.$route)
        }
	}
</script>

<style lang="scss">
	.main-container{
		margin: 0 12px;
		// display: flex;
		// flex-direction: column;
		// //height: 89vh;
		// min-height: 89vh;
		// justify-content: space-between;
		min-height: calc(100vh - 120px);
	}

	.app-container{
		background-color: var(--v-background-base);
	}

	
</style>