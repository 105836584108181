import {$api} from "@/services";
import {$locationsApi} from "@/services/external/locationsAPI";
import {$flujoApi} from "@/services/external/flujoAPI";

export default function(store) {
    try {
      store.$api = $api;
      store.$locationsApi = $locationsApi;
      store.$flujoApi = $flujoApi;
    } catch (e) {
      console.error(e);
    }
}