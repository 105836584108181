import Vue from 'vue'
import VueRouter from 'vue-router';

import {routes} from "./routes";
import store from "../store";

Vue.use(VueRouter)



const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
    
  //AuthMiddleware({ to, from, next })
  if (!to.meta.middlewares) {
    store.state.layout = 'blank'
      return next()
  }
  const middlewares = to.meta.middlewares
  Object.keys(middlewares).forEach(middleware => {
    middlewares[middleware]({ to, from, next })
  })
  return next()
})

export default router


