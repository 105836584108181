<template>
  <v-footer color="transparent" >
  
       <v-row align="center" no-gutters>
         <v-col class="col-12 col-md-1">
            <v-img src="./../assets/logo-merca.svg" contain max-width="100%" max-height="25" class="mr-2 footer-icon" :class="$vuetify.theme.dark ? 'icon-white' : 'icon-dark' "></v-img>
       
         </v-col>

         <v-col>
            <p class="footer-text mb-0"><span>&#8739;</span> Copyright <span>&#169;</span>   {{ new Date().getFullYear() }} Todos los derechos reservados, Yego y todas sus marcas son parte de Grupo Merca</p>
         </v-col>
        </v-row>
     
  </v-footer>

     <!-- <v-img src="./../assets/logo-merca.svg" contain max-width="6%" class="mr-2 footer-icon" :class="$vuetify.theme.dark ? 'icon-white' : 'icon-dark' "></v-img>
          <p class="footer-text mb-0"><span>&#8739;</span> Copyright <span>&#169;</span>   {{ new Date().getFullYear() }} Todos los derechos reservados, Yego y todas sus marcas son parte de Grupo Merca</p>
       -->
</template>

  
<script>
	export default {
		name: 'AppFooter',
		
	};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

  .footer{
    &-container{
    clear: both;
    position: relative;
    height: 200px;
    margin-top: 100px;
    }

    &-text{
      font-size: small;
      color: gray;
      margin-top: 2px;
      @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
        text-align: center;
      }
    }
    &-icon{
      margin-top: 2px !important;
    }
  }
</style>